import logo from '../assets/logo.png'
import cc from '../assets/cc-by-nc-sa.png'
function Footer() {
    return (
        <footer className="App-footer">
            <a className='footer-logo'
                href="https://lespetitsdebrouillards-aura.org"
                title="vers le site des Petit⋅es Débrouillard⋅es Aura">
                <img src={logo} 
                    alt="logo Petits Débrouillards" />
            </a>
            <p><span className="footer-title">Paroles d'écrans</span> - 2023</p>
            <p>vidéos sous licence CC-BY-NC-SA<br/>
                <a href="https://creativecommons.org/licenses/by-nc-sa/4.0/deed.fr">
                <img src={cc} 
                    alt="note de licence Creative Commons BY NC SA" 
                    style={{maxWidth:'100px'}} />
                </a>
            </p>
            <div className='footer-clear'></div>
        </footer>
    )
}

export default Footer