import Saison1 from "../components/Saison1"
import Saison2 from "../components/Saison2"
// import TagsContent from "../components/TagsContent"
import Fancybox from '../components/Fancybox'

import { HashLink } from 'react-router-hash-link';
import scrollWithOffset from "../assets/scroll";

function NoFilter() {

    return (
        <div>
            <h2  id="title">NO FILTER - capsules vidéos</h2>
            <p>
                Des vidéos réalisées par des adolescents et des jeunes, à propos de leurs pratiques du numérique.
            </p>
            <h3>Licence</h3>
            <p className="max700">
                Les vidéos sont sous la licence <span className="biblio">Creative Commons Attribution - Pas d’Utilisation Commerciale - Partage dans les Mêmes Conditions 4.0 International</span>. Vous avez notamment le droit de diffuser les vidéos dans un cadre non commercial.
                Pour en savoir plus, consultez <a href="https://creativecommons.org/licenses/by-nc-sa/4.0/deed.fr">cette note sur la licence</a>.
            </p>
            
            <Fancybox>
                {/* <div id="guide-pedagogique" style={{display: 'none', maxWidth: '500px'}}>
                    <h2>Guide pédagogique</h2>
                    <p>Télécharger le guide pédagogique ici. </p>
                    <p>Quelques petites questions juste avant. </p>
                    <p>Vos Prénom et Nom : <input type="text"/></p>
                    <button>Valider et télécharger le guide</button>
                </div> */}
                {/* <div>
                    <button className="fancy-link" data-fancybox="" data-src="#guide-pedagogique">Télécharger le guide pédagogique</button>
                </div> */}

                <h3 id="saison1">Saison 1</h3>
                {/* <TagsContent/> */}
                <Saison1/> 
                <h3 id="saison2">Saison 2</h3>
                {/* <TagsContent/> */}
                <Saison2/> 
                <div>
                    <HashLink smooth to="/interventions#title" 
                    scroll={el => scrollWithOffset(el)} 
                    className="fancy-link">
                        Vous accueillez des groupes de jeunes (12-25 ans), <br/>
                        participez vous aussi au projet NO FILTER
                    </HashLink>
                </div>   
            </Fancybox>
        </div>
    )
}

export default NoFilter