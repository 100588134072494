
import '../assets/App.css';
import Home from './Home';
import NoFilter from './NoFilter';
import Interventions from './Interventions';
import Layout from './Layout';
import NoPage from './NoPage';
import { BrowserRouter, Routes, Route } from "react-router-dom";


function App() {
  return (
    <div className="App">
       <BrowserRouter>
       <Routes>
        <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="nofilter" element={<NoFilter/>} />
            <Route path="interventions" element={<Interventions/>} />
            <Route path="*" element={<NoPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
