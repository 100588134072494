import VideoPreview from './VideoPreview'

function Saison2() {
    
    return (
        <ul id="video-list">
            <VideoPreview 
                episode={5} 
                title="Tu fais quoi quand t'es pas sur ton tel ?"
                thumbnail_path="/thumbnails/sans_ton_tel.jpg"
                // thumbnail_url=""
                thumbnail_alt="lire la vidéo Tu fais quoi quand t'es pas sur ton tel ?"
                video_url="https://www.youtube.com/embed/sW1j3O7KTdg"
                tags={['nudge','ikea']}>
                <p>Non cette question ne suggère pas l’idée que les adolescents n’auraient pas d’autres occupations que l’utilisation leur téléphone !</p>
                <p>Au contraire, il est important de faire tomber quelques idées reçus à propos des adolescents et des générations assimilées à l’essor des technologies numériques.</p>
                <p>Les premières recherches sur les « générations numériques » il y a une quinzaine d’année ont fait apparaître le terme de digital native qui a été repris maintes fois. Le concept de digital natives ("natifs du numérique" en français) est apparu pour la première fois en 2001 dans l’article « Digital natives, Digital immigrants » de Marc Prensky, chercheur américain spécialiste des questions d’éducation à l’heure du numérique. Il définissait alors les digital natives comme les individus nés après 1980, avec le langage numérique (ordinateurs, jeux vidéo, internet) pour « langue maternelle ».</p>
                <p>Aujourd’hui le regard scientifique majoritaire remet en cause le bien-fondé de ce concept en soulignant la diversité des pratiques numériques chez les jeunes, et en distinguant la capacité à utiliser un outil de la maîtrise de cet outil.</p>
                <p>On estime qu’en 2020, <a href="https://www.santepubliquefrance.fr/content/download/230409/2504914">les jeunes de 14 à 25 ans passent environ 3 heures par jour sur les écrans</a>. Les usages sont variés, en allant des jeux vidéos et loisirs liés à l’ordinateur, à la consommation de contenu audiovisuel (télévisions, vidéos sur d’autres écrans), à toutes formes de communications...</p>
                <p>Au delà de ces trois heures, les jeunes ont de nombreuses occupations, n’incluant pas les écrans comme élément central.</p>
                <p>De manière générale, les jeunes sont sportifs, et même les plus sportifs que le reste de la population, avec <a href="https://injep.fr/publication/les-pratiques-sportives-en-france-en-2023">71 % de pratique régulière chez les 15-24 ans</a>.</p>
                <p>Les jeunes sont également des lecteurs, avec un temps de lecture ayant doublé entre 2015 et 2023 passant de 10 minutes à 19 minutes par jours, hors livres numériques. Ce chiffre monte à 41 minutes pour tous supports confondus.  Les jeunes ne sont pas réticents à lire, <a href="https://centrenationaldulivre.fr/donnees-cles/les-jeunes-francais-et-la-lecture-en-2024">81 % d’entre eux se déclarent lecteurs et 84 % disent aimer lire</a>.</p>
                <p>De manière générale, les écrans participent également un accès croissant à la culture, en leur présence comme en leur absence.</p>
                <p>« Les 18-30 ans constituent de loin la classe d’âge la plus active et créative, même si d’importantes différences dans les activités culturelles sont observées en fonction des origines sociales. » <a href="https://injep.fr/wp-content/uploads/2019/11/IAS30_spectateur-acreateur_BD.pdf">(injep analyses et synthèses n°30, 2019)</a></p>
                <p>Salomé Saqué, dans son livre « Sois jeune et tais-toi », écrit : "La jeunesse n’est donc pas cette consommatrice passive de contenus numériques qui nous est resservie à longueur d’articles criant à l’effondrement culturel. Au contraire, elle se nourrit et produit elle-même énormément de culture, qu’elle soit numérique ou non ! On l’oublie bien vite, mais les jeunes figurent parmi les populations les plus adeptes des pratiques artistiques amateurs : la généralisation des tutos et la facilité de partage des contenus ont fait exploser ces pratiques individuelles amateurs – musique en tête."</p>
                <p>Les réseaux et internet sont d’ailleurs des vecteurs d’envies d’activités autres, notamment de lecture. <a href="https://centrenationaldulivre.fr/donnees-cles/les-jeunes-francais-et-la-lecture-en-2024 ">19 % ont déjà choisis un livre après en avoir entendu parler sur les réseaux sociaux</a> (comme Booktok, présent sur Tiktok), et 57 % après en avoir entendu parler sur internet.</p>
                <p>« Le temps consacré au numérique n’entame pas forcément le temps consacré à d’autres activités culturelles ou artistiques » </p>
                <p>Au contraire, les ordinateurs, tablettes et smartphones rendent possible la transition rapide entre différentes activités (photographie, films, lecture, écriture…), voire, la multi-activité et multi-supports. <a href="https://injep.fr/wp-content/uploads/2019/11/IAS30_spectateur-acreateur_BD.pdf">(injep analyses et synthèses n°30, 2019)</a></p>
            </VideoPreview>
            
        </ul>
    )
}

export default Saison2