import logoPetitsDebs from '../assets/logo.png'
import logoCebazat from '../assets/logo-cebazat.webp'
import logoAgartha from '../assets/logo-agartha.webp'
import logoAmicado from '../assets/logo-amicado.png'

import thumbnailSaison1 from '../assets/saison1.png'
import komorebi from '../assets/komorebi.jpg'

import { HashLink } from 'react-router-hash-link';
import scrollWithOffset from "../assets/scroll";
function Home() {
    return (
        <div>
            <h1>Partager la parole des jeunes<br/>à propos de leurs pratiques numériques</h1>
                <p className='max700'>
                Les écrans sont présents dans la vie des adolescent⋅e⋅s et des jeunes. Plus largement le « numérique » est présent quotidiennement. 
                Les sources d’information multimédias sont multiples, d’accès fréquents et instantanés, sollicitant et captant leur attention. 
                </p>
                <p className='max700'>
                Nous sommes conscients que c’est une évolution sociale et technologique majeure des 20 dernières années et qu’elle a des conséquences importantes sur l’ensemble des situations qu’ils vivent tous les jours (acheter, s’informer, dialoguer, regarder ou produire des images, écouter de la musique…). 
                Nouvel espace de socialisation, internet, les réseaux sociaux numériques sont devenus des plateformes  incontournables de développement personnel,  de partage, de construction collective des savoirs et de la culture. 
                </p>
                <p className='max700'>
                    Le dispositif <strong>Paroles d'écrans</strong> comprend 2 volets :
                </p>
                <p className='max700'>
                    ● NO FILTER est un travail d’enquête auprès des adolescent⋅e⋅s et des jeunes en proposant une démarche partagée avec elles et eux. 
                    Les rencontres permettent aux jeunes de filmer leurs échanges, que vous retrouvez ici.
                </p>
                <p className='max700'>
                    ● OK BOOMER s'adresse aux différents acteurs éducatifs. 
                    Nous vous proposons d'animer des échanges à partir des capsules vidéos afin de prendre conscience de la réalité des pratiques numeriques des jeunes.
                    
                
                </p>
            <h2>Une campagne lancée par :</h2>
                <a href="https://lespetitsdebrouillards-aura.org">
                    <img src={logoPetitsDebs} 
                        alt="vers le site des Petits Débrouillards Aura"
                        style={{maxWidth:'100px'}} />
                </a>
            <h2>Découvrez les vidéos NO FILTER</h2>
                <div>
                    <HashLink 
                        smooth 
                        to="/nofilter#saison1" 
                        scroll={el => scrollWithOffset(el)} 
                        className="fancy-link">
                        <img src={thumbnailSaison1}
                            alt="" 
                            style={{width:'100%', maxWidth:'300px'}} />
                            <br/>
                        Saison 1
                    </HashLink>
                </div>
            <h2 id="partenaires">Partenaires</h2>
            <div className='partenaires'>
            <section>
                <h3>L’Agartha à Cébazat<br/>(Puy de Dôme)</h3>
                <a href="https://agartha6.wixsite.com/agartha-cebazat">
                    <img src={logoAgartha} 
                    alt="vers le site Agartha" 
                    style={{maxHeight:'90px'}} />
                    </a>
                <img src={logoCebazat} 
                    alt="logo Cébazat service jeunesse" 
                    style={{maxWidth:'100px'}} />
                <p>
                    L’Agartha est une structure municipale pilotée par le Service Enfance et Jeunesse. 
                    Elle est animée par et pour les jeunes de 11 à 25 ans, et encadrée par une équipe composée d’un responsable référent et d’une coordination jeunesse.
                    L’Agartha est située 19 rue Jean Jaurès dans le coeur de Cébazat. 
                </p>
            </section>
            <section>
                <h3>L’Amic’Ado<br/>(Puy de Dôme)</h3>
                <a href="https://agartha6.wixsite.com/agartha-cebazat">
                    <img src={logoAmicado} 
                    alt="vers le site Amic'Ado"
                    style={{maxHeight:'110px'}} 
                    /></a>
                <p>
                    Lieu d’accueil pour les adolescentes et les adolescents, 
                    l’Amicado existe depuis une vingtaine d’année. 
                    Elle est localisé dans l’ancienne Poste à Vertaizon.
                </p>
            </section>
            <section>
                <h3>(komorebi)<br/>Thibaud Déchance</h3>
                <a href="https://linktr.ee/K0M0R3B1">
                    <img src={komorebi} 
                    alt="vers le linktree de komorebi"
                    style={{maxHeight:'170px'}} 
                    />
                    </a>
                <p>
                    (komorebi) c'est Thibaud, cadreur et monteur autodidacte, 
                    qui réalise des ateliers d'éducation aux images en direction du jeune public 
                    ainsi que des captations d'événements, des publicités ou encore des clips.
                </p>
            </section>
            <section>
                <h3>Judith Parsotam et Mathieu Salet<br/>L3 en Psychologie (UCA)</h3>
                {/* <a href="https://linktr.ee/K0M0R3B1">
                    <img src={komorebi} 
                    alt="vers le linktree de komorebi"
                    style={{maxHeight:'170px'}} 
                    />
                    </a> */}
                <p>
                    Judith Parsotam et Mathieu Salet, éudiant⋅e⋅s en licence de Psychologie à l'Université Clermont Auvergne (UCA), 
                    ont contribué aux textes associés aux vidéos.
                </p>
            </section>
            <section>
                <h3>Leïa Hernandez<br/>M1 en Psychologie (UCA)</h3>
                {/* <a href="https://linktr.ee/K0M0R3B1">
                    <img src={komorebi} 
                    alt="vers le linktree de komorebi"
                    style={{maxHeight:'170px'}} 
                    />
                    </a> */}
                <p>
                    Leïa Hernandez, éudiante en master de Psychologie à l'Université Clermont Auvergne (UCA), 
                    a contribué aux textes associés aux vidéos.
                </p>
            </section>
            </div>

            <h2>Vous souhaitez une intervention, devenir partenaire ?</h2>
                <p>
                    Vous êtes une structure éducative (collectivité, organisme de formation, association ...)
                </p>
                <p>
                    Vous souhaitez un temps d'échange autour des pratiques numériques des jeunes ?
                </p>
                <HashLink 
                    smooth to="/interventions#title" 
                    scroll={el => scrollWithOffset(el)} 
                    className="fancy-link">
                    Demandez une intervention   
                </HashLink>
        </div>
    )
}

export default Home