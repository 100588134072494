// import TagsButtons from './TagsButtons'
import Fancybox from './Fancybox'

import '../assets/Videos.css'

function VideoPreview({thumbnail_url,thumbnail_path,thumbnail_alt,video_url,episode,title,tags,children}) {
    const thumbnailSrc = thumbnail_path!=null ? thumbnail_path : thumbnail_url;

    return (
        <div className='preview'>
            <Fancybox>
                <a className='preview-image' data-fancybox={`episode-${episode}`} href={video_url}>
                    <img src={thumbnailSrc} alt={thumbnail_alt || 'vignette de la vidéo'} />
                </a>
            </Fancybox>
            <div className='details'>
                <h4>
                    <span className='episode'>Épisode {episode} : </span>
                    {title || 'Sans titre'}
                </h4>
                <>{children || 'en cours d\'écriture'}</>
                {/* <TagsButtons tags={tags}/> */}
            </div>
        </div>
    )
}

export default VideoPreview