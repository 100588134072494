import VideoPreview from './VideoPreview'

function Saison1() {
    
    return (
        <ul id="video-list">
            <VideoPreview 
                episode={1} 
                title="Temps sur les écrans"
                thumbnail_path="/thumbnails/temps_sur_les_ecrans.jpg"
                // thumbnail_url=""
                thumbnail_alt="lire la vidéo Temps sur les écrans"
                video_url="https://www.youtube.com/embed/WO302lj9w4M"
                tags={['nudge','ikea']}>
                    <p>
                    Une méta-analyse de 2022 estime que le temps d’écran moyen des enfants âgés en moyenne de 9 ans est de 162 minutes par jour soit presque 3h/jour <span className='biblio'>(Madigan et al., 2022)</span>. 
                    L’utilisation quotidienne du numérique par les jeunes a amené les chercheurs à se poser des questions sur l’impact qu’il peut avoir sur la santé, la sociabilité, le développement des enfants. 
                    </p>
                    <p>
                    Des études indiquent qu’une hausse du temps d’écran est associé a des effets néfastes sur le corps les relations et la santé mental <span className='biblio'>(Carson et al., 2016)</span> dû entre autre à la sédentarité. 
                    De plus l’augmentation de l’utilisation des écrans est associée à une diminution du temps passé à jouer. Or le jeu est essentiel au bon développement de l’enfant sur le plan physique, psychomoteur, cognitif, social et émotionnel <span className='biblio'>(Whitebread et al., s. d.)</span>. 
                    </p>
                    <p>
                    Ces différentes observations ont amené l’Organisation Mondial de la Santé (OMS) a développé un guide des bonnes utilisation du numérique pour les enfants <span className='biblio'>(WHO Guidelines on Physical Activity and Sedentary Behaviour, s. d.)</span>, 
                    dans lequel il déconseille d’exposer des enfants de 0 à 2 ans à des écrans, pour ceux de 2 à 4 ans il ne faut pas dépasser 1h d’écran.
                    </p>
            </VideoPreview>
            <VideoPreview episode={2} title="Ordinateur ou téléphone ?" 
                thumbnail_path="/thumbnails/pc_ou_tel.jpg" 
                thumbnail_alt="lire la vidéo Ordinateur ou téléphone ?" 
                video_url="https://youtube.com/embed/oJLAExc_mv8" 
                tags={['ikea']}>
                    <p>
                    En 2021, la répartition des équipements pour les adolescents étaient la suivante : 95% possèdent un smartphone et 68% un ordinateur
                    L’équipement en smartphone est devenu quasiment universel seuls 5% des jeunes de 15-16 ans parmi les enquêtés n’en disposent pas. 
                    </p><p>
                    Le développement du téléphone portable lors de la première décennie du XXIè a lentement mais sûrement renforcé les usages nomades. Les forfaits téléphoniques proposés par les industries de la communication (Bouyg, Orange, Free…) ont largement participé à l’élargissement de l’équipement et ont concerné la génération des 12 - 16 ans. 
                    Bien qu’en 2007, le I-phone n’est pas encore très populaire, moins de douze ans plus tard en 2019 les connexions Internet se font à 66% à partir d’un smartphone. 
                    </p><p>
                    La connexion à Internet n’est plus du tout associé à l’ordinateur de bureau. Le monde des applications fait son apparition : « There ’s an app for that ». L’adolescent est un mobinautes. 
                    Ses applications sont ouvertes en permanence. La présence systématique du téléphone permet non seulement d’entretenir plusieurs conversations par texto et par la messagerie des divers réseaux sociaux mais encore d’avoir des activités permanentes comme  faire des photos les publier, recevoir des photos, jouer en ligne, naviguer sur le web et écouter de la musique.
                    De même, il est devenu courant de regarder des séries sur des sites de vidéo à la demande ou du streaming en direct dans les transports en communs.
                    </p><p>
                    L’ordinateur est le plus souvent relégué à une fonction scolaire (recherche documentaire, écriture…) à l’exception de quelques activités qui nécessitent impérativement un ordinateur (infographie, jeux vidéo...)
                    </p>
                    <p><strong>Source :</strong> <span className='biblio'>Observatoire 2021, Région Normandie, Cemea</span></p>.
            </VideoPreview>
            <VideoPreview episode={3} title="Quelles applis tu utilises ?" 
                thumbnail_path="/thumbnails/quelles_applis.jpg" 
                thumbnail_alt="lire la vidéo Quelles applis tu utilises ?" 
                video_url="https://www.youtube.com/embed/Rmjo4uykRO4" 
                tags={['ikea']}>
                    <p>
                    On constate que ce sont les réseaux sociaux qui sont les applications les plus utilisées par les adolescents. 
                    Mais même au sein de ces applications il y a une hiérarchie. En 2022 le top 3 des réseaux les plus utilisés est constitué d’Instagram, Snapchat et Tiktok. 
                    C’est la 1ère fois que Tiktok intègre cette position anciennement occupé par Facebook. Le réseau social crée par Mark Zuckerberg disparaît petit à petit du paysage. 
                    En effet les jeunes de moins de 18 ans ne sont que 21% à l’utiliser. C’est donc Facebook le réseau le plus supprimé (10%) suivi par Tiktok et twitter (tous 27%) et Instagram (6%). 
                    </p><p>
                    Les raisons qui poussent les jeunes à supprimer ces réseaux sont variables. On retrouve par exemple le fait qu’ils ne l’utilisent plus, que le contenu ne les intéresse pas ou encore qu’ils jugent passer trop de temps avec. 
                    Les réseaux sont utilisés principalement pour discuter avec des amis (78%), passer le temps (74%) ou s’informer (65%). 
                    </p><p>
                    Mais les réseaux n’ont pas que des bons côtés 15% des 16-18ans ont étaient cyberharcelés, le plus souvent par des inconnues (57%) ou des camarades de classes (44%). 
                    Les réseaux sur lesquelles ont constate le plus de cyberharcèlement sont Facebook suivi par Instagram et Snapchat.
                    </p>
            </VideoPreview>
            <VideoPreview episode={4} title="Quelle appli tu préfères ?"
                thumbnail_path="/thumbnails/quelle_appli_pref.jpg" 
                thumbnail_alt="lire la vidéo Quelles applis tu préfères ?" 
                video_url="https://www.youtube.com/embed/qbo8wM9dsws" 
                tags={['ikea']}>
                    <p>
                    Les jeunes ont des représentations d’internet variées. Ils se le représentent d’abords comme un outil de communication, cette image augmente avec l’âge (70,7% des primaires et 98,8% des lycéens).
                    </p><p>
                    Ensuite il est perçu comme un outil de divertissement (vidéo, jeux, musique…) cette idée est plus présente chez les garçons que chez les filles. 
                    On retrouve aussi la représentation d’un outil de recherche, « une grande bibliothèque ».
                    </p><p>
                    Enfin il est vu comme une plateforme commerciale là encore cette vision est plus marquée chez les lycéens que chez les primaire ou les collégiens. 
                    Mais ce n’est pas pour autant qu’ils sont inconscient des risques et des aspects négatifs que peut avoir internet. 
                    On constate un baisse des représentations exclusivement positives avec l’âge, passant de 57,3% en primaire à 33,1% au lycée. 
                    </p>
            </VideoPreview>
            
        </ul>
    )
}

export default Saison1