
function Interventions() {
    return (
        <div>
            <h2 id="title">Nos interventions</h2>
            <section>
                <h3 class="contact">Contact</h3>
                <p className="max700">
                    Nous vous proposons des interventions, avec des jeunes ou les personnes qui les accompagnent et sont curieux⋅ses de leurs pratiques sur internet.
                    Nous sommes disponibles sur la région Auvergne-Rhône-Alpes.
                </p><p className="max700">
                    Pour toute demande, contactez-nous<br/><strong>par email :</strong> <a href="mailto:contact@parolesdecrans.org">contact@parolesdecrans.fr</a>
                    <br/>ou <strong>par téléphone :</strong> 07 62 65 11 88
                </p>
            </section>
            <div className="cards-container">
                <section>
                    <h3>NO FILTER<br/>Échanges entre jeunes</h3>
                    <p>Une série de rencontres entre jeunes pour qu’ils
                    puissent échanger entre eux sur leurs pratiques
                    d’internet et sur les réseaux sociaux, sans devoir
                    répondre d’abord aux questions que les adultes se
                    posent pour eux...
                    </p>
                    <div>
                        <p><strong>Âge conseillé :</strong> ados only !</p>
                    </div>
                </section>
                <section>
                    <h3>OK BOOMER<br/>Échanges / animation autour des pratiques numériques des jeunes</h3>
                    <p>
                    Ateliers menés à partir du visionnage des
                    capsules vidéos NO FILTER, et de l’expérience de chacun.
                    </p>
                    <div>
                        <p><strong>Format :</strong> 2 heures à une journée</p>
                        <p><strong>Âge conseillé :</strong> adultes</p>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default Interventions