import { Outlet } from "react-router-dom";
import Footer from "../components/Footer";

import logo from '../assets/paroles.png'

import { HashLink } from 'react-router-hash-link';
import scrollWithOffset from "../assets/scroll";

const Layout = () => {
  return (
    <>
        <header className="App-header">
            <img src={logo} 
              className="App-logo" 
              alt="logo Paroles d'écrans"
              aria-hidden="true"
              />
            <span id="sitename">Paroles d'écrans</span>
            <nav>
                <HashLink smooth to="/#top">Accueil</HashLink>
                
                <HashLink 
                  smooth 
                  scroll={el => scrollWithOffset(el)} 
                  to="/nofilter#title">NO FILTER<br/><span className="mini">capsules vidéos</span></HashLink>

                <HashLink 
                  smooth 
                  scroll={el => scrollWithOffset(el)} 
                  to="/interventions#title">Nos interventions</HashLink>

                <HashLink 
                  smooth 
                  scroll={el => scrollWithOffset(el)} 
                  className="mini"
                  to="/#partenaires">Partenaires</HashLink>
            </nav>
        </header>

        <main>
            <Outlet />
        </main>
        <Footer/>
    </>
  )
};

export default Layout;
